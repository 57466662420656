import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: { 
    activeCart: false,
    productModal: {},
    freebee: {},
    /**
     * Armazena os valores de itens de composicoes que foram
     * selecionados, e no front permite pegar o valor do produto
     * e somar com essa varial, tendo valor final
     * @var int
     */
    valueProductCompositions: 0,
    cart: {},
    productsSuggested: []
  },
  actions,
  getters,
  mutations,
};