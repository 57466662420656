<template>
    <div class="modal-terms-conditions-itens-text">
        <h2>Terms and Conditions</h2>
        <b>1. Application Objective and Scope</b><br>
        <div class="text-ident">
            The website www.capricciosa.com.pt provides the public with an online store that sells food
            products, produced and sold by Grupo Capricciosa, S.A. and partner entities. Grupo
            Capricciosa, S.A, is the owner of the website www.capricciosa.com.pt.<br>
            These General Terms and Conditions of Use (henceforward referred to as “Terms and
            Conditions) apply to all users who browse the website www.grupocapricciosa.com., as to all
            commercial transactions completed there.<br>
            The website navigation, as well as the purchase of any product in the online store, corresponds
            to a declaration of understanding, agreement and acceptance of these General Terms and
            conditions, by all its users and Customers (designation for any and all purchasers of products
            through this website)
        </div>
        <div class="text-ident">
            <br><b>2. Website Ownership <u>www.capricciosa.com.pt</u></b><br>
            The website www.capricciosa.com.pt is registered under the &quot;Regulation of .PT domains /
            subdomains registration&quot;, with the legal deposit no. 376640/14, and is the exclusive property
            of Grupo Capricciosa, SA, company headquartered at Armazém 8, Piso 1, Doca de Santo
            Amaro, 1350-353 Lisbon, registered at the Commercial Registry Office of Lisbon under the
            unique registration and legal person/fiscal number 505215144, with a share capital of EUR
            135.000,00 (one hundred and thirty five thousand euros).
            <br>
            For any clarification related to the website www.capricciosa.com.pt or to these General Terms
            and Conditions, the following contacts should be used:
            <br>
            Email: geral@grupocpa.pt<br>
            Mail: Armazém 8, Piso 1, Doca de Santo Amaro, 1350-353 Lisbon.<br>
            Phone: 213 942 410<br>
        </div>
        <div class="text-ident">
            <br><b>3. Service, Shops and Hours</b><br>
            Grupo Capricciosa offers users a take-away service (for pick-up at one of Capricciosa&#39;s shops)
            of the products displayed on the website www.capricciosa.com.pt Each product that is there
            and for sale is properly identified through the sale designation or denomination, accompanied
            by a brief description of the product (for example, the ingredient composition), an image and
            the corresponding sale price, in addition to other commercial information that Grupo
            Capricciosa considers relevant for the correct information to the User.<br>
            The User can choose one of the following Capricciosa restaurants to pick up his order:<br>
            <ul>
                <li>Capricciosa Doca de Santo Amaro;</li>
                <li>Capricciosa Parque das Nações;</li>
                <li>Capricciosa Carcavelos;</li>
                <li>Capricciosa Cascais.</li>
            </ul>
            Capricciosa&#39;s take-away service is available during Capricciosa&#39;s opening hours, from 12 pm to
            11:30 pm, every day of the week.<br>
            Grupo Capricciosa reserves the right to change the opening hours without prior notice.
        </div>
        <div class="text-ident">
            <br><b>4. Purchasing Online</b><br>
            To make purchases through the website www.capricciosa.com.pt, the Customer must select all
            products they want to order and follow all the steps purchasing steps so that it can be
            successfully completed. For order completion, it is necessary to fill in all the purchase fields
            and complete the payment process.<br>
            The User must ensure that all data relating to e-mail address and telephone contact are
            correctly provided.<br>
            Each time an order is made through the website www.capricciosa.com.pt an email / sms will
            be sent to the User confirming and accepting the order and indicating the approximate time
            until in-store collection - Capricciosa only accepts orders for collection on the day itself.<br>
            At the estimated time, the User must go to the chosen Capricciosa establishment and collect
            the order.<br>
            The validation of the purchase order implies that the Customer is aware of and expressly
            accepts these General Terms and Conditions.
        </div>
        <div class="text-ident">
            <br><b>5. Cancellation</b><br>
            It is not possible to cancel or change the order once the payment has been made.
        </div>
        <div class="text-ident">
            <br><b>6. Sale of alcoholic beverages</b><br>
            When placing an order for Products that include alcoholic beverages, the User confirms that he
            is at least 18 years old. Grupo Capricciosa reserves the right to refuse to sell and deliver alcohol
            to anyone who does not appear to be at least 18 years of age or who cannot prove to be 18
            years of age. Grupo Capricciosa also reserves the right to refuse delivery to anyone under the
            influence of alcohol or drugs.
        </div>
        <div class="text-ident">
            <br><b>8. Irregular situations</b><br>
            Users should report to Capricciosa Group the existence of any problem with their order or any
            product anomaly immediately after delivery, by telephone to 213 942 410 or by email to
            geral@grupocpa.pt.
        </div>
        <div class="text-ident">
            <br><b>9. Price and Payment</b><br>
            The prices of the Products are indicated on the website www.capricciosa.com.pt, and include
            VAT or any other legally required fee.<br>
            Grupo Capricciosa reserves the right to update this information at any time regarding
            commercial offers, including, without limitation, changes in the sale price, in the available
            products, as well as in the applicable promotional and commercial conditions.<br>
            Payment for all orders are exclusively through the MBWay channel.
        </div>
        <div class="text-ident">
            <br><b>10. Grupo Capricciosa’s Responsibility</b><br>
            Navigation and use of the www.capricciosa.com.pt website is at the Users&#39; sole risk. The
            www.capricciosa.com.pt website and its contents are provided without warranty of any kind,
            whether express or implied, including, but not limited to, the implied warranties of
            merchantability, non-infringement of third party rights and suitability for a particular purpose ,
            or any guarantee that access to the site will be uninterrupted or error-free, that they are safe
            or free from viruses or other harmful material, or that the information will be complete,
            accurate or timely. In particular, Grupo Capricciosa assumes no responsibility or liability for
            any: i) errors or inaccurate content; ii) personal injury or damage to property, of any nature,
            resulting from accessing and using the www.capricciosa.com.pt website; iii) unauthorized
            access or use of our secure servers and / or personal and / or financial information stored on
            them, iv) interruptions or cessation of transmission to or from the www.capricciosa.com.pt
            website; v) errors, viruses, trojan viruses or others that may be transmitted to or through the
            website www.capricciosa.com.pt by third parties, and / or; vi) errors or omissions in the
            content or any loss or damage that has occurred as a result of using content published, sent,
            transmitted or made available through the website www.capricciosa.com.pt.<br>
            Grupo Capricciosa reserves the right to restrict or terminate access to the
            www.capricciosa.com.pt website or any resource or part of it at any time, with or without
            prior notice.<br>
            The User agrees that, to the fullest extent permitted by law, Grupo Capricciosa, its managers,
            employees and agents are exempt from any guarantees, express or implied, in relation to the
            services offered on the website www.capricciosa.com.pt and to respective use.<br>
            The Capricciosa Group does not guarantee, promote or assume responsibility for Products
            advertised or offered by third parties through the website www.capricciosa.com.pt or any
            hyperlink or highlight in banners or in any other advertising format. Grupo Capricciosa will be
            responsible for monitoring any transaction between the user and third party suppliers of
            products or services.
        </div>
        <div class="text-ident">
            <br><b>11. Users&#39; Obligations</b><br>
            Users have to comply with and respect these Terms and Conditions, and to adopt the following
            behaviors:
            i) Refrain from introducing, storing or broadcasting through the website
            www.capricciosa.com.pt defamatory, obscene, insulting, xenophobic and / or any other
            content that violates the general principles of law and public order;<br>
            ii) Save and do not disclose the login password on the www.capricciosa.com.pt website in
            order to prevent third parties from accessing your account;<br>
            iii) Do not use false identities;<br>
            iv) Provide personal data and the correct addresses so that orders can be properly processed
            and delivered;<br>
            v) Refrain from introducing links on this website, regardless of the intended purpose, without
            prior authorization from the entity that owns it. The use of the www.capricciosa.com.pt
            domain for abusive purposes, and without prior authorization, is subject to recourse to the
            competent legal means by Grupo Capricciosa.<br>
            vi) transmit messages or information that is insulting, misleading, defamatory, obscene or that
            violates, in any way, the right to privacy, intellectual or industrial property of third parties, as
            well as the availability of any information that could be harmful to third parties or to the
            website owner.<br>
            Grupo Capricciosa reserves the right to delete the User and / or Customer account that
            materializes any of these situations.
        </div>
        <div class="text-ident">
            <br><b>12  . Confidentiality</b><br>
            Any communication, information or other content transmitted by Users to the website
            www.capricciosa.com.pt, will be considered as non-confidential and not reserved. When
            transmitting content to the www.capricciosa.com.pt site, the user is automatically granting
            Grupo Capricciosa a royalty-free, perpetual, irrevocable and non-exclusive license to use,
            reproduce, modify, publish, edit, translate, distribute, execute and display such content
            separately and as part of other works in any form, medium, or technology that is currently
            known or that will be developed in the future and to sub-license such rights. The user agrees
            that Grupo Capricciosa is free to use any ideas, concepts, know-how or techniques contained
            in any content that it transmits to this website for any possible purpose, including, but not
            limited to, development, manufacture and marketing of products, and Grupo Capricciosa does
            not need to provide any compensation or recognition to the User in exchange for such use.<br>
            Grupo Capricciosa assumes to process and use personal data in accordance with the Personal
            Data Protection Law, in order to allow User access to data related to this or to correct or add
            information, if requested. Pursuant to the provisions of the Personal Data Protection Law,
            Users are expressly recognized the right to access, rectify, cancel and oppose their data.<br>
            Users can, at any time, express their desire not to receive any kind of advertising or
            information.
        </div>
        <div class="text-ident">
            <br><b>13. Intellectual Property</b><br>
            The www.capricciosa.com.pt website contains several registered trademarks that are used to
            distinguish the products or services of Grupo Capricciosa. These brands may not be copied
            without the express authorization of Grupo Capricciosa.<br>
            Grupo Capricciosa owns all intellectual property rights in the content that is available on the
            website www.capricciosa.com.pt, and it cannot be used, copied, reproduced, distributed,
            transmitted or sold for any other purpose without consent of Grupo Capricciosa. The content
            provided by the website www.capricciosa.com.pt is provided for personal use only. The User
            undertakes not to interfere or compromise the security related to the use of the
            www.capricciosa.com.pt website and its contents.<br>
            The use of the www.capricciosa.com.pt website for illegal purposes or any other purposes that
            may be considered unworthy of the image of the www.capricciosa.com.pt website is expressly
            prohibited. Usurpation, counterfeiting, use of usurped or counterfeit content, illegitimate
            identification and unfair competition are criminally punishable.
        </div>
        <div class="text-ident">
            <br><b>14. Cookie Policy</b><br>
            - What are Cookies
            &quot;Cookies&quot; are data files that a website sends to your computer while you are visiting the
            website. These data files include information that allows you to memorize important data that
            will make your navigation more efficient and useful. This website uses cookies for various
            purposes, namely to obtain non-personal data from online visitors.<br>
            - How are cookies used?<br>
            Website visitors use different computers and web browsers. To make your visits as simple as
            possible, a record of the type of browser (e.g. Internet Explorer, Google Chrome) and the
            operating system (e.g. Windows, Macintosh) used and the domain name of your Internet
            service provider.<br>
            - What if the User does not want to accept Cookies?<br>
            If the user does not want to accept Cookies, he can configure his computer to be notified
            whenever a cookie is being sent or to disable all Cookies through his browser. The user should
            check the HELP menu of his browser to find out the best way to change or update his cookies.
            For more information about cookies and how to prevent them from being installed or how to
            delete existing cookies from your hard drive, the user can visit the following website: 
            http://www.allaboutcookies.org.
        </div>
        <div class="text-ident">
            <br><b>15. Applicable law. Forum</b><br>
            The Terms and Conditions will be governed, executed and interpreted in accordance with
            Portuguese law, namely the Code of Copyright and Related Rights, the Code of Industrial
            Property and the Law on Computer Crime.
        </div>
    </div>
</template>
<script>
export default {
}
</script>