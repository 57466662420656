<template>
	<div class="modal-policy-privacy-itens-text">
		<h2>Política de Privacidade</h2>
		Respeitamos a sua privacidade bem como os seus dados pessoais, tomando todas as medidas
		necessárias para os manter em segurança.
		<br><br>
		A presente Política de Privacidade é válida única e exclusivamente na utilização e no
		processamento de dados neste website.
		<br><br>
		<b>1. Responsável pelo tratamento de dados</b>
		<br>
		O Grupo Capricciosa é a entidade responsável pela recolha e tratamento dos dados, agindo de
		acordo com os critérios determinados pelo Grupo Capricciosa que decide as categorias de
		dados recolhidos, qual o tratamento dos mesmos e as finalidades para que são utilizados. O
		Grupo Capricciosa integra na sua estrutura organizativa um Encarregado de Proteção de dados
		pessoais (DPO) que estará disponível para lhe prestar qualquer informação relativa ao
		tratamento dos seus dados pessoais pela Responsável, incluindo a lista dos nossos
		subcontratantes em matéria de proteção de dados pessoais. É possível contactar o DPO
		enviando um email para dpo@grupocpa.pt
		<br><br>
		<b>2. Privacidade</b>
		<br>
		Ao entrar neste website são utilizados cookies, ou seja, pequenos ficheiros de texto que são
		colocados no disco rígido por um servidor de páginas Web, que permitem uma melhor
		experiência de navegação e acesso a outras funcionalidades do website, tudo isto para prestar
		um serviço de excelência ao utilizador.
		<br><br>
		<b>3. Recolha de dados pessoais</b>
		<br>
		Os dados pessoais recolhidos neste website têm como finalidade responder a pedidos de
		contacto, de orçamentos, de informações em geral e, para efeitos legais necessários caso
		venha a celebrar algum contrato ou transação comercial com a nossa empresa e as
		informações, pessoais ou não, não são transmitidas a terceiros.<br>
		Os dados dos utilizadores ficam guardados em servidores Cloud localizados na União Europeia.
		Estes servidores são protegidos e mantidos de acordo com os mais elevados standards de
		segurança e respeitando sempre as leis de privacidade aplicáveis.
		<br><br>
		<b>4. Direitos dos titulares dos dados</b>
		<br>
		O utilizador tem o direito de nos termos da lei, consultar, eliminar ou proceder à retificação
		dos seus dados pessoais, enviados pelo nosso website. Para este efeito, e para reconhecermos
		a identidade do titular dos dados, os utilizadores devem enviar uma mensagem a partir do seu
		próprio email dirigida a geral@grupocpa.pt indicando de forma clara o que pretende
		relativamente aos seus dados pessoais.
		<br><br>
		<b>5. Período de tempo durante o qual guardamos os dados</b>
		<br>
		Conservaremos os seus dados pessoais pelo período necessário à prestação dos serviços,
		respetiva faturação e finalidades referidas.
		<br><br>
		<b>6. Direito de reclamação</b>
		<br>
		O utilizador tem o direito a apresentar queixa na Autoridade de Supervisão da Proteção de
		Dados (https://www.cnpd.pt).
	</div>
</template>

<script>
export default {
}
</script>