export default {  
  setCompany(state, value){
    state.company = value;
    localStorage.setItem('company', JSON.stringify(value));
  },
  setGroup(state, value){
    state.group = value;
  },
  setStore(state, value){
    state.store = value;
  },
  setCampaigns(state, value){
    state.campaigns = value;
  },
  setCalendar(state, value){
    state.calendar = value;
  },
};