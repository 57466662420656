<template>
	<div class="modal-policy-privacy-itens-text">
		<h2>Privacy Policy</h2>
		We respect your privacy as well as your personal data, taking all necessary measures to keep
		them safe.
		<br><br>
		This Privacy Policy is valid solely and exclusively for the use and processing of data on this
		website.
		<br><br>
		<b>1. Responsible for data processing</b>
		<br>
		Grupo Capricciosa is the entity responsible for the collection and processing of data, acting in
		accordance with the criteria determined by Grupo Capricciosa, that decides the categories of
		data collected, how they are processed and the purposes for which they are used. Grupo
		Capricciosa includes in its organizational structure a Personal Data Protection Officer (DPO)
		who will be available to provide you with any information regarding the processing of your
		personal data by the Responsible, including the list of our subcontractors in terms of personal
		data protection. It is possible to contact the DPO by sending an email to dpo@grupocpa.pt
		<br><br>
		<b>2. Privacy</b>
		<br>
		When entering this website, cookies are used, that is, small text files that are placed on the
		hard disk by a web page server, which allow a better browsing experience and access to other
		website features, all of this to provide a optimization for the user.
		<br><br>
		<b>3. Collection of personal data</b>
		<br>
		The personal data collected on this website are intended to respond to requests for contact,
		quotes, purchases, information in general and, for legal purposes necessary if you enter into
		any contract or commercial transaction with our company and the information, personal or
		not, is not are passed on to third parties.
		User data is stored on Cloud servers located in the European Union. These servers are
		protected and maintained in accordance with the highest security standards and always
		respecting applicable privacy laws.
		<br><br>
		<b>4. Rights of data subjects</b>
		<br>
		The user has the right, under the law, to consult, delete or correct their personal data sent by
		our website. For this purpose, and in order for us to recognize the identity of the data subject,
		users must send a message from their own email addressed to geral@grupocpa.pt clearly
		indicating what they want regarding their personal data.
		<br><br>
		<b>5. Periodicity</b>
		<br>
		We will keep your personal data for the period necessary to provide the services, their billing
		and the aforementioned purposes.
		<br><br>
		<b>6. Right of complaint</b>
		<br>
		The user has the right to file a complaint with the Data Protection Supervisory Authority
		(https://www.cnpd.pt).
	</div>
</template>

<script>
export default {
}
</script>