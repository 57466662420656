import store from '@/store/index.js';

export default function money (value) {	
    let currency = CURRENCIES[store.getters['Store/store'].currency] || CURRENCIES.EUR;

    value = parseFloat(value);
    value = isNaN(value) ? 0 : value;

    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat(currency.locale, {
        style: 'currency',
        currency: currency.currency,
        minimumFractionDigits: currency.fraction
    });

    return formatter.format(value);
}

const CURRENCIES = {
    EUR: {
        locale: 'pt-PT',
        currency: 'EUR',
        fraction: 2
    }
}