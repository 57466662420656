export default {
    isLoading: state => state.loading,
    menuOpen(state) {
        return state.menuOpen;
    },
    isDesktop(state) {
        return state.isDesktop;
    },
    activeBackdrop(state) {
        return state.activeBackdrop;
    },
    isScrolled(state) {
        return state.isScrolled;
    },
    siteConfigs(state) {
        return state.siteConfigs;
    }
};
