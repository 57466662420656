import Vue from 'vue'
import Vuex from 'vuex'

import Site from './Site';
import Store from './Store';
import Cart from './Cart';
import Order from './Order';

Vue.use(Vuex);

export default new Vuex.Store({ 
  modules: {
    Site,
    Store,
    Cart,
   Order
  }
});