<template>
    <div class="modal-terms-conditions-itens-text">
        <h2>Termos e Condições</h2>
        <b>1. Objeto e Âmbito de Aplicação</b><br>
        <div class="text-ident">
            O sítio www.capricciosa.com.pt disponibiliza ao público uma loja online que comercializa
            produtos alimentares, produzidos e colocados à venda pelas entidades parceiras da sociedade
            Grupo Capricciosa, S.A, entidade proprietária do sítio www.capricciosa.com.pt.
            Os presentes Termos e Condições Gerais de Utilização (de ora em diante designados por
            “Termos e Condições”) aplicam-se a todos os utilizadores que naveguem no sítio
            www.capricciosa.com.pt, bem como a todas as transações comerciais aí realizadas.
            A navegação no sítio, bem como a compra de qualquer produto na loja online, corresponde a
            uma declaração de entendimento, concordância e aceitação dos presentes Termos e
            Condições Gerais, por parte dos seus utilizadores e Clientes (expressão que designa todo e
            qualquer adquirente de produtos através deste sítio).
        </div>

        <div class="text-ident">
            <br><b>2. Da Titularidade do sítio <u>www.capricciosa.com.pt</u></b><br>
            O sítio www.capricciosa.com.pt encontra-se registado ao abrigo do &quot;Regulamento do registo
            de domínios/subdomínios de .PT&quot;, com o depósito legal n.º 376640/14, e é propriedade
            exclusiva do Grupo Capricciosa, S.A., sociedade com sede na Armazém 8, Piso 1, Doca de Santo
            Amaro, 1350-353 Lisboa, matriculada na Conservatória do Registo Comercial de Lisboa sob o
            número único de matrícula e de pessoa coletiva n.º 505215144, com o capital social de EUR
            135.000,00 (cento e trinta e cinco mil euros).
            Para qualquer esclarecimento relacionado com o sítio www.capricciosa.com.pt ou com os
            presentes Termos e Condições Gerais deverão ser utilizados os seguintes contactos:<br>
            Email: geral@grupocpa.pt<br>
            Carta: Armazém 8, Piso 1, Doca de Santo Amaro, 1350-353 Lisboa.<br>
            Telefone: 213 942 410.<br>
        </div>

        <div class="text-ident">
            <br><b>3. Serviço, Estabelecimentos e Horário</b><br>
            O Grupo Capricciosa oferece aos Utilizadores um serviço de take away (para levantamento
            num dos estabelecimentos Capricciosa) dos Produtos indicados no sítio
            www.capricciosa.com.pt Cada produto aí indicado e colocado para venda encontra-se
            devidamente identificado através da respetiva designação ou denominação de venda,
            acompanhado de uma breve descrição do mesmo (por exemplo, indicação dos ingredientes),

            de uma imagem e do correspondente preço de venda, para além de outras informações
            comerciais que o Grupo Capricciosa considere relevantes para a correta informação ao
            Utilizador.<br>
            O Utilizador poderá escolher um dos seguintes restaurantes Capricciosa para levantamento do
            seu pedido:<br>
            <ul>
                <li>Capricciosa Doca de Santo Amaro;</li>
                <li>Capricciosa Parque das Nações;</li>
                <li>Capricciosa Carcavelos;</li>
                <li>Capricciosa Cascais.</li>
            </ul>
            O serviço take away da Capricciosa está disponível dentro do horário de funcionamento dos
            estabelecimentos Capricciosa, das 12 às 23:30 horas, todos os dias da semana.<br>
            O Grupo Capricciosa reserva o direito de alterar o horário sem aviso prévio.
        </div>

        <div class="text-ident">
            <br><b>4. Realização da encomenda online</b><br>
            Para a realização de compras através do sítio www.capricciosa.com.pt o Cliente deverá
            selecionar todos os produtos que pretende encomendar e seguir todos os passos da compra
            para que a mesma fique finalizada com sucesso. Para o efeito é necessário preencher todos os
            campos de compra e concluir o processo de pagamento.<br>
            O Utilizador deverá certificar-se que todos os dados relativos a endereço eletrónico e contacto
            telefónico são fornecidos corretamente.<br>
            Sempre que for feita uma encomenda através do sítio www.capricciosa.com.pt será enviado
            um e-mail/sms ao Utilizador confirmando e aceitando a encomenda e a indicar o tempo
            aproximado até ao levantamento - sendo que a Capricciosa apenas aceita encomendas para
            levantamento no próprio dia.<br>
            Na hora estimada, o Utilizador deve deslocar-se ao estabelecimento Capricciosa escolhido e
            efetuar o levantamento da encomenda.<br>
            A validação do pedido de compra implica que o Cliente tomou conhecimento e aceita, de
            forma expressa, os presentes Termos e Condições Gerais.
        </div>

        <div class="text-ident">
            <br><b>5. Cancelamento</b><br>
            A partir do momento do pagamento, não é possível fazer cancelamento ou alteração da
            compra.
        </div>

        <div class="text-ident">
            <br><b>6. Venda de bebidas alcoólicas</b><br>
            Ao fazer uma encomenda de Produtos que inclua bebidas alcoólicas, o Utilizador confirma que
            tem pelo menos 18 anos de idade. O Grupo Capricciosa reserva o direito de recusa de entrega
            de álcool a qualquer pessoa que não aparente ter pelo menos 18 anos de idade ou que não
            possa provar ter 18 anos de idade. O Grupo Capricciosa reserva igualmente o direito de recusa
            de entrega a qualquer pessoa que esteja sob a influência de álcool ou drogas.
        </div>

        <div class="text-ident">
            <br><b>8. Situações irregulares</b><br>
            Os Utilizadores deverão comunicar ao Grupo Capricciosa a existência de qualquer problema
            com a sua encomenda ou anomalia do Produto entregue imediatamente após a sua entrega,
            por via telefónica para o número 213 942 410 ou por correio eletrónico para o endereço
            geral@grupocpa.pt.
        </div>

        <div class="text-ident">
            <br><b>9. Preço e Pagamento</b><br>
            Os preços dos Produtos são os indicados no sítio www.capricciosa.com.pt, e incluem o IVA ou
            qualquer outra taxa legalmente exigível.<br>
            O Grupo Capricciosa reserva-se no direito de atualizar a qualquer momento a informação
            disponibilizada referente às ofertas comerciais, incluindo, sem limitar, alterações no preço de
            venda, no tipo de produtos disponibilizados, bem como nas condições promocionais e
            comerciais aplicáveis.<br>
            O pagamento das encomendas poderá ser feito exclusivamente por Mbway.
        </div>

        <div class="text-ident">
            <br><b>10. Responsabilidade do Grupo Capricciosa</b><br>
            A navegação e utilização do sítio www.capricciosa.com.pt é do risco exclusivo dos Utilizadores.
            O sítio www.capricciosa.com.pt e os respetivos conteúdos são fornecidos sem garantias de
            tipo algum, sejam expressas ou implícitas, incluindo, mas sem limitação, garantias implícitas de
            comercialização, não-infração de direitos de terceiros e adequação a um propósito particular,
            ou qualquer garantia de que o acesso ao sítio será ininterrupto ou isento de erros, de que
            estão seguros ou isentos de vírus ou de outro material prejudicial, ou de que as informações
            serão completas, precisas ou oportunas. Em especial, o Grupo Capricciosa não assume
            qualquer responsabilidade ou obrigação por quaisquer: i) erros ou conteúdos poucos precisos;
            ii) danos pessoais ou danos à propriedade, de qualquer natureza, resultantes do acesso e
            utilização do sítio www.capricciosa.com.pt; iii) acessos não autorizados ou utilização dos
            nossos servidores seguros e/ou de informação pessoal e/ou financeira neles armazenada, iv)

            interrupções ou cessação da transmissão de ou para o sítio www.capricciosa.com.pt; v) erros,
            vírus, vírus trojan ou similares que possam ser transmitidos para ou através do sítio
            www.capricciosa.com.pt por terceiros, e/ou; vi) erros ou omissões nos conteúdos ou qualquer
            perda ou dano ocorrido como resultado da utilização de conteúdos publicados, enviados,
            transmitidos ou disponibilizados através do sítio www.capricciosa.com.pt.<br>
            O Grupo Capricciosa reserva o direito de restringir ou encerrar a qualquer momento o acesso
            ao sítio www.capricciosa.com.pt ou a qualquer recurso ou parte do mesmo, com ou sem
            notificação prévia.
            <br>
            O Utilizador concorda que, em toda a extensão permitida pela lei, o Grupo Capricciosa, os seus
            gerentes, colaboradores e agentes estão isentos de quaisquer garantias, expressas ou
            implícitas, em relação aos serviços oferecidos no sítio www.capricciosa.com.pt e à respetiva
            utilização.
            <br>
            O Grupo Capricciosa não garante, promove ou assume a responsabilidade por Produtos
            anunciados ou oferecidos por terceiros através do sítio www.capricciosa.com.pt ou qualquer
            hiperligação ou destaque em banners ou em qualquer outro formato de publicidade. O Grupo
            Capricciosa será responsável por monitorizar qualquer transação entre o utilizador e terceiros
            fornecedores de produtos ou serviços.
        </div>

        <div class="text-ident">							
            <br><b>11. Obrigações dos Utilizadores</b><br>
            Os Utilizadores comprometem-se a cumprir e a respeitar os presentes Termos e Condições, e a
            adotar os seguintes comportamentos:<br>
            i) Abster-se de introduzir, armazenar ou difundir através do sítio www.capricciosa.com.pt
            conteúdos difamatórios, obscenos, injuriosos, xenófobos e/ou de qualquer outra índole que
            violem os princípios gerais de direito e a ordem pública;<br>
            ii) Guardar e não divulgar, a sua password de entrada no sítio www.capricciosa.com.pt de
            modo a evitar que terceiros acedam à sua conta;<br>
            iii) Não utilizar identidades falsas;<br>
            iv) Facultar os dados pessoais e as moradas corretas de modo a que as encomendas possam
            ser devidamente processadas e entregues;<br>
            v) Abster-se de introduzir links no presente site, independentemente do fim pretendido, sem
            autorização prévia da entidade proprietária do mesmo. A utilização do domínio
            www.capricciosa.com.pt com fins abusivos, e sem autorização prévia, é suscetível de recurso
            aos meios legais competentes por parte do Grupo Capricciosa.
            <br>
            vi) transmitir mensagens ou informação injuriosa, enganosa, difamatória, obscena ou que
            viole, por qualquer forma, o direito à intimidade ou à propriedade intelectual ou industrial de
            terceiros, bem como a disponibilização de qualquer informação que possa ser prejudicial para
            terceiros ou para o titular do site.
            <br>
            O Grupo Capricciosa reserva o direito a eliminar a conta do Utilizador e/ou do Cliente que
            concretize alguma destas situações.
        </div>

        <div class="text-ident">
            <br><b>12. Confidencialidade</b><br>
            Qualquer comunicado, informação ou outro conteúdo transmitido pelos Utilizadores para o
            sítio www.capricciosa.com.pt, será considerado como não confidencial e não reservado. Ao
            transmitir conteúdo para o sítio www.capricciosa.com.pt, o utilizador está automaticamente a
            conceder ao Grupo Capricciosa uma licença isenta de royalties, perpétua, irrevogável e não
            exclusiva para usar, reproduzir, modificar, publicar, editar, traduzir, distribuir, executar e exibir
            tal conteúdo separadamente e como parte de outros trabalhos em qualquer forma, meio, ou
            tecnologia que seja atualmente conhecida ou que venha a ser desenvolvida no futuro e a sub-
            licenciar tais direitos. O utilizador concorda que o Grupo Capricciosa está livre para usar
            quaisquer ideias, conceitos, know-how ou técnicas contidas em qualquer conteúdo que
            transmita a este website para qualquer propósito possível, incluindo, mas sem que isto
            constitua limitação, desenvolvimento, fabricação e comercialização de produtos, e que o
            Grupo Capricciosa, não precisa fornecer nenhuma compensação ou reconhecimento ao
            Utilizador em troca de tal uso.<br>
            O Grupo Capricciosa compromete-se a processar e utilizar os dados pessoais em conformidade
            com a Lei da Proteção de Dados Pessoais, de forma a permitir o acesso do Utilizador aos dados
            relacionados com este ou a corrigir ou acrescentar informações, se tal for solicitado. Nos
            termos das disposições da Lei da Proteção de Dados Pessoais, aos Utilizadores é reconhecido
            expressamente o direito de acesso, retificação, cancelamento e oposição em relação aos seus
            dados.<br>
            Os Utilizadores podem, a qualquer altura, manifestar a sua vontade de não receberem
            qualquer tipo de publicidade ou informação.
        </div>

        <div class="text-ident">
            <br><b>13. Propriedade Intelectual</b><br>
            O sítio www.capricciosa.com.pt contem diversas marcas registadas que são utilizadas para
            distinguir os produtos ou serviços do Grupo Capricciosa. Estas marcas não podem ser copiadas
            sem a autorização expressa do Grupo Capricciosa.
            <br><br>
            O Grupo Capricciosa é titular de todos os direitos de propriedade intelectual do conteúdo que
            se encontra disponível no sítio www.capricciosa.com.pt, não podendo o mesmo ser usado,
            copiado, reproduzido, distribuído, transmitido ou vendido para outro fim sem o
            consentimento prévio do Grupo Capricciosa. O conteúdo disponibilizado pelo sítio
            www.capricciosa.com.pt é facultado apenas para utilização pessoal. O Utilizador compromete-
            se em não interferir ou comprometer a segurança relacionada com a utilização do sítio
            www.capricciosa.com.pt e respetivos conteúdos.<br>
            Está expressamente proibida a utilização do sítio www.capricciosa.com.pt para fins ilegais ou
            quaisquer outros fins que possam ser considerados indignos da imagem da sítio
            www.capricciosa.com.pt. A usurpação, contrafação, aproveitamento do conteúdo usurpado ou
            contrafeito, a identificação ilegítima e concorrência desleal são puníveis criminalmente.
        </div>

        <div class="text-ident">
            <br><b>14. Política de Cookies</b><br>
            - O que são Cookies
            Os &quot;Cookies&quot; são ficheiros de dados que um website envia para o seu computador enquanto
            está a visitar o website. Estes ficheiros de dados incluem informação que permite memorizar
            dados importantes que tornarão a sua navegação mais eficiente e útil. Este website utiliza
            cookies para fins diversos, nomeadamente para obter dados de caráter não pessoal dos
            visitantes online.<br>
            - Como são utilizados os Cookies?<br>
            Os visitantes do website utilizam diferentes computadores e browsers Web. Para tornar as
            suas visitas o mais simples possível, é guardado automaticamente um registo do tipo de
            browser (por exemplo, Internet Explorer, Google Chrome) e do sistema operativo (por
            exemplo, Windows, Macintosh) utilizados e o nome do domínio do seu fornecedor de serviços
            Internet.<br>
            - E se o Utilizador não quiser aceitar os Cookies?
            Se o utilizador não quiser aceitar os Cookies, poderá configurar o seu computador para que
            seja avisado sempre que um cookie esteja a ser enviado ou desativar todos os Cookies através
            do seu browser. O utilizador deve verificar o menu de AJUDA do seu browser para descobrir a
            melhor forma de alterar ou atualizar os seus cookies. Para mais informações sobre cookies e
            sobre como impedir que estes sejam instalados ou sobre como apagar os cookies existentes do
            seu disco rígido, o utilizador poderá visitar o seguinte
            website:http://www.allaboutcookies.org.
        </div>

        <div class="text-ident">
            <br><b>15. Lei aplicável. Foro</b><br>
            Os Termos e Condições serão regidos, executados e interpretados em conformidade com a lei
            Portuguesa, nomeadamente, o Código do Direito de Autor e dos Direitos Conexos, o Código da
            Propriedade Industrial e a Lei da Criminalidade Informática.
        </div>
    </div>
</template>
<script>
export default {
}
</script>